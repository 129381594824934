export const BuiltInAddresses = {
    mainnet: {
        'nameRegistry:set_child_record': {
            address: 'KT1QHLk1EMUA8BPH3FvRUeUmbTspmAhb7kpd',
        },
        'nameRegistry:update_record': {
            address: 'KT1H1MqmUM4aK9i1833EBmYCCEfkbt6ZdSBc',
        },
        'nameRegistry:claim_reverse_record': {
            address: 'KT1TnTr6b2YxSx2xUQ8Vz3MoWy771ta66yGx',
        },
        'nameRegistry:update_reverse_record': {
            address: 'KT1J9VpjiH5cmcsskNb8gEXpBtjD4zrAx4Vo',
        },
        nameRegistry: {
            address: 'KT1F7JKNqwaoLzRsMio1MQC7zv3jG9dHcDdJ',
            resolveProxyContract: true,
        },
        'tldRegistrar:tez:commit': {
            address: 'KT1P8n2qzJjwMPbHJfi4o8xu6Pe3gaU3u2A3',
        },
        'tldRegistrar:tez': {
            address: 'KT1P8n2qzJjwMPbHJfi4o8xu6Pe3gaU3u2A3',
            resolveProxyContract: true,
        },
        'tldRegistrar:tez:buy': {
            address: 'KT191reDVKrLxU9rjTSxg53wRqj6zh8pnHgr',
        },
        'tldRegistrar:tez:renew': {
            address: 'KT1EVYBj3f1rZHNeUtq4ZvVxPTs77wuHwARU',
        },
        'tldRegistrar:tez:bid': {
            address: 'KT1CaSP4dn8wasbMsfdtGiCPgYFW7bvnPRRT',
        },
        'tldRegistrar:tez:withdraw': {
            address: 'KT1CfuAbJQbAGYcjKfvEvbtNUx45LY5hfTVR',
        },
        'tldRegistrar:tez:settle': {
            address: 'KT1MeFfi4TzSCc8CF9j3qq5mecTPdc6YVUPp',
        },
    },
    custom: null,
    florencenet: {
        'nameRegistry:set_child_record': {
            address: 'KT1UG9DUYqtCF7XUqjH4QNGF2naoh7D1mscR',
        },
        'nameRegistry:update_record': {
            address: 'KT1GZQLAbp9ekSHutKzaoo6yW5AofhYPRQ3q',
        },
        'nameRegistry:claim_reverse_record': {
            address: 'KT1GJFHpra47WtwZH9uVsjwLPqgfL7LDQJhp',
        },
        'nameRegistry:update_reverse_record': {
            address: 'KT1X21jVp6ooHAkPCj3zvCWyNoqQ312dPFid',
        },
        nameRegistry: {
            address: 'KT1PCx2zUqbaCtg8UcyHn31cMD9yaMHLvL6N',
            resolveProxyContract: true,
        },
        'tldRegistrar:flo:commit': {
            address: 'KT1GTSgwuuB5c2ceGhqhTk4nedZznXYFzh1J',
        },
        'tldRegistrar:flo': {
            address: 'KT1GTSgwuuB5c2ceGhqhTk4nedZznXYFzh1J',
            resolveProxyContract: true,
        },
        'tldRegistrar:flo:buy': {
            address: 'KT1E8XRnTzrF49Z33wuB5WzEurPGrN1ASb1w',
        },
        'tldRegistrar:flo:renew': {
            address: 'KT1DFMXRc57XmK1YCZnYtYM7ZZCZ1CTCF7Q3',
        },
        'tldRegistrar:flo:bid': {
            address: 'KT1W89CnCQxAwaZRnRefyAnBU7rUBP8s6fez',
        },
        'tldRegistrar:flo:withdraw': {
            address: 'KT1AheQhiqZRW3SGX7XiRJZgZzGfNTQNV4nQ',
        },
        'tldRegistrar:flo:settle': {
            address: 'KT1SmSRa18zpw5G514LJBkZm8ynAxrKpp5Tg',
        },
        'tldRegistrar:a1:commit': {
            address: 'KT1JP6eYx6Z53SW8TCrKZCcZno61CJUjWvWp',
        },
        'tldRegistrar:a1': {
            address: 'KT1JP6eYx6Z53SW8TCrKZCcZno61CJUjWvWp',
            resolveProxyContract: true,
        },
        'tldRegistrar:a1:buy': {
            address: 'KT1NJbBbczKYNF3CWn9pXwLjhSg99jUG2Fjd',
        },
        'tldRegistrar:a1:renew': {
            address: 'KT1FPh3iejT98uTsxH1fSz3WPGnMmMjLpwDp',
        },
        'tldRegistrar:a1:bid': {
            address: 'KT1NyigaEvn3Xpmnp567H7De1hqaTAo9drNh',
        },
        'tldRegistrar:a1:withdraw': {
            address: 'KT1VbTTGzABsZez3YHmZo2NnAmjZf6bfddwJ',
        },
        'tldRegistrar:a1:settle': {
            address: 'KT1PZfDZ5JiTmxKuxL9hteXafyjUY6evrFwX',
        },
        'tldRegistrar:a2:commit': {
            address: 'KT1XiyeQzUWPpo1x8AYGQZA4wEDhVAfkKg99',
        },
        'tldRegistrar:a2': {
            address: 'KT1XiyeQzUWPpo1x8AYGQZA4wEDhVAfkKg99',
            resolveProxyContract: true,
        },
        'tldRegistrar:a2:buy': {
            address: 'KT1AgkojjS56AF4n1Kg8ueWyUK1uNWncjRrE',
        },
        'tldRegistrar:a2:renew': {
            address: 'KT1WQ4S1UamDy1g5UBLvozSgprStrq6i3xrx',
        },
        'tldRegistrar:a2:bid': {
            address: 'KT1RfaxpqJ2rGD3umeS85N4e7PGGibA5Qcgg',
        },
        'tldRegistrar:a2:withdraw': {
            address: 'KT1FQjUog81bN1ixL6U3X5EWzrD87qkCic3y',
        },
        'tldRegistrar:a2:settle': {
            address: 'KT1LdvFBCftEZ1fkwBseXkxx8XfAiCmqtRuE',
        },
        'tldRegistrar:a3:commit': {
            address: 'KT1MiAUbR16Sz3vWpp8HYdiiybGeLvzH6inP',
        },
        'tldRegistrar:a3': {
            address: 'KT1MiAUbR16Sz3vWpp8HYdiiybGeLvzH6inP',
            resolveProxyContract: true,
        },
        'tldRegistrar:a3:buy': {
            address: 'KT18p1t2fyJTDALqzimiMQe7gAGHeFbc9PRt',
        },
        'tldRegistrar:a3:renew': {
            address: 'KT1Cx3iRQ2xZsDJ62FZ6hd4B8o9g4jq93N3J',
        },
        'tldRegistrar:a3:bid': {
            address: 'KT19WncaVpfnNTFnKDKJuztXWjvVjo198wKm',
        },
        'tldRegistrar:a3:withdraw': {
            address: 'KT19DyPDFYUbJa3EHKv8jz5Wsa36QWNVWY3k',
        },
        'tldRegistrar:a3:settle': {
            address: 'KT1MHCnvYGxLcqFyDFbdAXSGZUKrbREWQbdV',
        },
    },
    granadanet: {
        'nameRegistry:set_child_record': {
            address: 'KT1XHghF15LrLKx26T9UQnzGH4xq2gNiP24s',
        },
        'nameRegistry:update_record': {
            address: 'KT1BD2Adkewd48L7UG9gJ4AQaSV1hrn1g9fs',
        },
        'nameRegistry:claim_reverse_record': {
            address: 'KT1VBVyoez3bGsxpH5wEYdqgWPcNDUegdVsW',
        },
        'nameRegistry:update_reverse_record': {
            address: 'KT1HCnPDg57bFPteswdzomi77hFW6gj4ZeEm',
        },
        nameRegistry: {
            address: 'KT1NkgWqwycZhFWUBZKqkqUSJF34zK5Kk5XJ',
            resolveProxyContract: true,
        },
        'tldRegistrar:gra:commit': {
            address: 'KT1Pg2i2fySptcJg3QEwvQzTUPAyoGADNSLe',
        },
        'tldRegistrar:gra': {
            address: 'KT1Pg2i2fySptcJg3QEwvQzTUPAyoGADNSLe',
            resolveProxyContract: true,
        },
        'tldRegistrar:gra:buy': {
            address: 'KT1UTcSJ4dq6TyqSw2KszYAdy49vq8VccFWK',
        },
        'tldRegistrar:gra:renew': {
            address: 'KT1JaAeVFyu1QHEBZaxw67KMudMWYbgZyyxC',
        },
        'tldRegistrar:gra:bid': {
            address: 'KT1U3xEWLEACaGAgLefKc2FYVi6QrpTwKCM7',
        },
        'tldRegistrar:gra:withdraw': {
            address: 'KT18tests1z6bKK9rQG15Bx5rPZdJYXoLxbH',
        },
        'tldRegistrar:gra:settle': {
            address: 'KT1EcQ3qTJCt26EHuxek1dCpjP2pP7XyTciQ',
        },
        'tldRegistrar:a1:commit': {
            address: 'KT1P7FBvQJDDYTUEtv78y1HAybqCLrm3To7Y',
        },
        'tldRegistrar:a1': {
            address: 'KT1P7FBvQJDDYTUEtv78y1HAybqCLrm3To7Y',
            resolveProxyContract: true,
        },
        'tldRegistrar:a1:buy': {
            address: 'KT1CAayhBdT8iHAj9k3DEFMFX4j3ArU6nssd',
        },
        'tldRegistrar:a1:renew': {
            address: 'KT1TFw52G2dYJRDkWojBLUU2k8FEiBnKTUKR',
        },
        'tldRegistrar:a1:bid': {
            address: 'KT1CA3Yn7ZmUHxXrmFeonjtcwJ87phN8w3Wg',
        },
        'tldRegistrar:a1:withdraw': {
            address: 'KT1XGqG2h4EDW54hCqNrq5BZfVakCQgCh6mH',
        },
        'tldRegistrar:a1:settle': {
            address: 'KT1UGwZcM9mZsTmdaMt6LnLKV82xH4Dphy65',
        },
        'tldRegistrar:a2:commit': {
            address: 'KT18i1CSF9HxePLo1mMuKpwxSAKppGcHvLvz',
        },
        'tldRegistrar:a2': {
            address: 'KT18i1CSF9HxePLo1mMuKpwxSAKppGcHvLvz',
            resolveProxyContract: true,
        },
        'tldRegistrar:a2:buy': {
            address: 'KT1MZA6J177BqMLafXU3nhjQuFWV99ronQD7',
        },
        'tldRegistrar:a2:renew': {
            address: 'KT1FE9X6SpV1KHxUfTVFmrCMuDc52QfEtryH',
        },
        'tldRegistrar:a2:bid': {
            address: 'KT1FKoKNSLS7gPpWCjy13USXpVE1op7YrxVt',
        },
        'tldRegistrar:a2:withdraw': {
            address: 'KT1FcFhDw4yXWSNeuJFbs54SAZXRkwyrSV3a',
        },
        'tldRegistrar:a2:settle': {
            address: 'KT1AvFE9zGKmoJMhg9ea3GYWrr7Ce1sSMTyc',
        },
        'tldRegistrar:a3:commit': {
            address: 'KT1NbGJxKuiB4ysV8LBZfBq5tUVus9k2EibB',
        },
        'tldRegistrar:a3': {
            address: 'KT1NbGJxKuiB4ysV8LBZfBq5tUVus9k2EibB',
            resolveProxyContract: true,
        },
        'tldRegistrar:a3:buy': {
            address: 'KT19vqaPkGs4ABTLLsKSYjXsRbxpBHBH6Bhm',
        },
        'tldRegistrar:a3:renew': {
            address: 'KT1NhRK8n9ka3zuToCAbWdrxz2eVzbkQWGFQ',
        },
        'tldRegistrar:a3:bid': {
            address: 'KT1GAqjuwvbw7tw2FsXw9FfbZKD5mSnZTvxm',
        },
        'tldRegistrar:a3:withdraw': {
            address: 'KT1EKs3Dc177tvSE9NKknLuMVjk4GjjW4cUx',
        },
        'tldRegistrar:a3:settle': {
            address: 'KT1GU1LG5fKmotTdGTc1aCzTtGZE7PjENWwo',
        },
    },
};
